* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
} 

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: grey;
}

.logo {
  max-width: 70px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.header h1 {
  font-family: 'Bayon', sans-serif;
  font-size: 1.5em;
  margin-top: 20px;
  letter-spacing: 0.2rem;
  font-weight: normal;
}

nav ul {
  text-align: center;
}

nav li {
  display: inline-block;
  padding: 20px;
  list-style: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875em;
}

nav a {
  text-decoration: none;
}

.about,
.contact-page {
  margin: 0 auto;
  padding: 20px;
  max-width: 700px;
  font-size: 0.875em;
}

.gallery-page {
  max-width: 1000px;
}

.home {
  max-width: 600px;
}

.abbie {
  width: 100%;
}

.gallery > ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.gallery img {
  max-width: 300px;
  padding: 20px;
  padding-bottom: 0;
}

.gallery p {
  margin: 0 20px;
  max-width: 260px;
  font-size: 0.875em;
}

.gallery .title {
  font-style: italic;
}

.gallery > li {
  height: 40vh;
  flex-grow: 1;
}

.gallery > li:last-child {
  flex-grow: 10;
}

.gallery > img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-aspect-ratio: 1/1) {
  .gallery > li {
    height: 30vh;
  }
}

@media (max-height: 480px) {
  .gallery > li {
    height: 80vh;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .gallery > ul {
    flex-direction: row;
  }

  .gallery > li {
    height: auto;
    width: 100%;
  }

  .gallery > img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
}

.about p,
.contact > p {
  margin: 20px 0 20px 0;
}

.about-text {
  text-align: justify;
}

.about-text,
.contact {
  margin: 40px 0;
}

.contact a {
  font-weight: bold;
}

.container {
  padding-bottom: 8rem;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer i {
  font-size: 1.5rem;
}

.footer a {
  color: black;
  padding: 10px;
}

.footer a:hover {
  color: grey;
}

nav .active {
  color: grey;
}

.exhibitions {
  text-align: left;
}

.exhibitions u {
  text-decoration: underline;
}
